<template>
  <div>
    <cost-add :joborderid="$route.params.id" @on-success="$router.push({name: 'job_orders-show', params:{id:$route.params.id}})">
      <template v-slot:footer>
        <vs-button :to="{name:'job_orders-show', params:{id: $route.params.id}}" type="border" color="warning">Back</vs-button>
      </template>
    </cost-add>
  </div>
</template>

<script>
import CostAdd from './sections/CostAdd.vue'
export default {
  components:{
    CostAdd
  }
}
</script>

<style>

</style>